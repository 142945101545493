import React, {useContext, useState} from 'react';
import {Context} from '../../AppContext';
import {Button} from '../../Widgets';
import {isVIP, isMonthly} from '../../Utils/UserUtil';
import CartItemTable from '../../Components/CartItemTable';
import PromotionSection from './PromotionSection';
const config = require('../../data.json');

export default function Bag(props) {
  const {goToNextStep, goToPrevStep} = props;
  const app = useContext(Context);
  const {profile, cart} = app.state;

  let creditsNotAllow =
    isVIP(profile) &&
    !isMonthly(profile) &&
    profile.credits < cart.calculations.amount;

  return (
    <div>
      <CartItemTable />

      <PromotionSection />

      {goToNextStep && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '20px 0px',
          }}>
          <Button
            type="default"
            style={{
              color: config.colors.borderSecond,
              borderColor: config.colors.borderSecond,
            }}
            onClick={goToPrevStep}>
            繼續購物
          </Button>
          <div style={{flex: 1}}></div>
          <Button onClick={goToNextStep}>下一步</Button>
        </div>
      )}

      {creditsNotAllow && (
        <div
          style={{
            margin: '20px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          您的點數餘額不足。
          <a href="/account_manage" style={{color: config.colors.main}}>
            前往儲值
          </a>
        </div>
      )}
    </div>
  );
}
