import React, {useContext} from 'react';
import styled from 'styled-components';
import {Row, Text, Checkbox, Button} from '../../Widgets';
// import PrintNotice from "../../Modals/PrintNotice";
// import Terms from "../../Modals/Terms";
import Terms, {metadata as termsMeta} from '../../Modals/Terms';
import PrintNotice, {
  metadata as printNoticeMeta,
} from '../../Modals/PrintNotice';
import {Context} from '../../AppContext';
const config = require('../../data.json');

export default function AgreementSection({setAgree, agree}) {
  const app = useContext(Context);

  // FIXME: Hardfix for set agreement to checked
  // this is a hard fix for not changinh parent code
  const [agreementCheck, setAgreeCheck] = React.useState({
    terms: false,
  });
  React.useEffect(() => {
    setAgree({terms: true});
    setAgreeCheck({
      terms: true,
    });
  }, []);

  return (
    <Wrapper>
      <Row wrap="nowrap" style={{alignItems: 'flex-start'}}>
        <Checkbox
          style={{marginRight: 10}}
          checked={agreementCheck.terms}
          onChange={(e) => {
            setAgree({terms: e.target.checked});
            setAgreeCheck({terms: e.target.checked});
          }}
        />
        <div>
          <Text size="sm">
            為維護您的權益，下單前請務必詳讀本網站所有『條款聲明』及『印刷須知』，我已閱讀並同意接受本網站(
            {config.information.name})客服中心所有服務條款。
          </Text>
          <Text size="sm" style={{marginBottom: 10}}>
            與{config.information.name}
            交易之始，即表示您同意上述須知事項。本網站保有審核、終止承攬與解釋條約之權利。
          </Text>

          <Row wrap="nowrap">
            <Button
              style={{borderRadius: 5}}
              onClick={() =>
                app.actions.setModal({
                  content: <Terms />,
                  ...termsMeta,
                })
              }>
              條款聲明
            </Button>
            <div style={{flexBasis: 20}} />
            <Button
              style={{borderRadius: 5}}
              onClick={() =>
                app.actions.setModal({
                  content: <PrintNotice />,
                  ...printNoticeMeta,
                })
              }>
              印刷須知
            </Button>
          </Row>
        </div>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid ${config.colors.border};
  background-color: #f7f7f7;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 5px;
`;
