function disabledEzship(cart, actions) {
  const {items = []} = cart;

  return items.some((item) => {
    let spec = actions.getProductFromSpec({productName: item.name});
    return !spec['allow_ezship'];
  });
}

function disabledEzshipItems(cart, actions) {
  const {items = []} = cart;

  return items.filter((item) => {
    let spec = actions.getProductFromSpec({productName: item.name});
    return !spec['allow_ezship'];
  });
}

export {disabledEzship, disabledEzshipItems};
