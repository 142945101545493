import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useMemo,
} from 'react';
import {Context} from '../../AppContext';
import {Pricetag} from '@styled-icons/ionicons-solid/Pricetag';
const appConfig = require('../../data.json');

export default function PromotionSection({}) {
  const [promos, setPromos] = useState({});
  const app = useContext(Context);
  const {cart} = app.state;

  const getPromos = useCallback(async () => {
    try {
      if (cart) {
        console.log('get promotions');
        let resp = await app.actions.getPromotions();
        setPromos(resp);
      }
    } catch (err) {
      console.warn(err);
    }
  }, [cart]);

  useEffect(() => {
    getPromos();
  }, [getPromos]);

  const result = useMemo(() => {
    let data;
    let result = [];

    data = promos['fee_promotion'];
    if (data) {
      let exclude = cart.items.reduce(
        (sum, x) => sum || data.exclude_product.indexOf(x.name) >= 0,
        false,
      );
      if (exclude) {
        result.push(`您的購物車包含無免運活動之商品，不符合${data.name}條件！`);
      } else {
        result.push(
          `訂單總金額差 ${promos['fee_promotion_diff']} 元 即享 ${data.name} 優惠！`,
        );
      }
    }

    let index = 1;
    while (true) {
      data = promos[`threshold_promotion_${index}`];
      if (!data) {
        break;
      }
      console.log('while');
      result.push(
        `訂單金額總差 ${promos['threshold_promotion_diff_' + index]} 元，即享 ${
          data.name
        } 優惠`,
      );
      index++;
    }

    return result;
  }, [promos, cart]);

  return result.length > 0 ? (
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {result.map((x, idx) => (
        <div
          key={idx}
          style={{
            padding: '4px 8px',
            margin: '0 5px 5px 0',
            border: 'solid 1px' + appConfig.colors.border,
            color: appConfig.colors.main,
          }}>
          <Pricetag
            color={appConfig.colors.main}
            size={18}
            style={{marginRight: 5}}
          />
          {x}
        </div>
      ))}
    </div>
  ) : null;
}
