import React from 'react';
import {Text} from '../../Widgets';
import ResultPage from '../../Components/ResultPage';

export default function EmptyCart(props) {
  return (
    <ResultPage image="/images/error-cart.png">
      <Text weight="600" style={{marginBottom: 5}}>
        您尚未將任何商品加入購物車，
      </Text>
      <Text>歡迎至商品頁面選購商品！</Text>
    </ResultPage>
  );
}
