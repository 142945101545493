import React, {useContext} from 'react';
import {PAYMENT_TYPES} from '../dictionary';
import {Context} from '../AppContext';
import {isVIP, isMonthly} from '../Utils/UserUtil';
const appConfig = require('../data.json');

export default function usePaymentTypeOptions({type}) {
  const app = useContext(Context);
  const {cart, profile} = app.state;
  let result = {
    options: [],
    message: '',
  };

  if (cart && profile) {
    const isPeriod = cart.items.find((item) => item.is_period);

    result.options = PAYMENT_TYPES.filter((option) => {
      if (isMonthly(profile)) {
        return option.memberType === 'monthly';
      } else if (isPeriod && appConfig.name === 'std') {
        // not allow to
        return option.periodOnly;
      } else {
        return !(option.memberType === 'monthly' || option.periodOnly);
      }
    }).filter((option) => {
      if (
        cart &&
        (cart.calculations.amount < 30 || cart.calculations.amount >= 20000)
      ) {
        // 超商代碼繳費 cvs allow amount between 30 and 20000
        return option.value !== 'cvs';
      }
      return true;
    });

    if (
      (!isMonthly(profile) && cart.calculations.amount < 30) ||
      cart.calculations.amount >= 20000
    ) {
      result.message = '超商付款需在30元 ~ 19999元區間';
    }

    if (type === 'credits' && profile.credits < cart.calculations.amount) {
      result.message =
        '您的點數餘額不足，若選擇「加值現金點數」請於結帳前完成點數加值。';
    }

    if (type === 'period') {
      result.message = '目前不支援訂閱制商品購買。';
    }
  }

  return result;
}
